header {
    width: 100%;
    margin-left: auto;
    font-size: 16px;
    position: fixed;
    z-index: 1;

}
.navbar {
    position: absolute;
    width: 100%;
    height: 3rem;
    top: 0;
    display: flex;
    font-weight: 600;
    align-items: center;
    color: #0087ee;
    background-color: #fff;
    border-bottom: 1px solid black;
    z-index:10;

    
}

.navbar .container__logo{
    display: "flex";
    flex-direction: row;
    font-size: 2vw;
    align-items: center;
    flex: 60%;
    margin-left:0.5rem;
}
.company_image {
    width: 5vw;
    display: "flex";
    flex-direction: row;
}
 a{
text-decoration: none;
}

.navbar .container {
    height: 100%;
    display: flex;
    flex: 80%;
    justify-content: space-between;
    align-items: center;
}

.navbar span {
    color: #ffffff;
}

.navbar .nav-menu {
    display: flex;
     list-style-type: none;

}
.how__we__diffrent{
    background-color: #c3c0f7;
    color: black;
    margin-right: 20px;
}
.navbar .signin__button {
    display: flex;
    flex: 10%;
    align-items: center;
}
.signin__button button {
    padding:7px 15px;
     border: 2px solid #0087ee;
    border-radius: 5px;
    -webkit-transition: opacity .2s,color .2s,border-color .2s,background-color .2s;
    transition: opacity .2s,color .2s,border-color .2s,background-color .2s;
    text-align: center;
    font-weight: 900;
    background-color: transparent;  
    color: #0087ee;

}
.after__signin{
      color: #0087ee;
      height: 4rem;
      display: flex;
      align-items: center;
}
.signin__button__mobile{
    display: none;
}

.navbar li {
    padding: 1rem;
}

.hamburger {
    display: none;
}

.navbar .icon {
    font-size: 1.5rem;
}
.company_profile {
    display: flex;
    padding-bottom: 10px;
    align-items: center;
}
.profile_icon {
    padding-right: 10px;
}

@media screen and (max-width:1040px) {
    .hamburger {
        display: block;
        margin-right: 20px;
    }
 .navbar .container__logo{
    font-size: 2rem;
    flex: 5%;
    margin-left: 25px;
}

    .navbar .nav-menu {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        top: 50px;
        right: -100%;
        width: 100%;
        background: rgba(255,255,255, .98);
        transition: 0.4s ease-in-out;
        z-index: 1000;
        
    }

    .navbar .active {
        right: 0;
    }

    .navbar .nav-menu li a {
        font-size: 1rem;
    }
    .navbar .signin__button{
    color: #0087ee;
    font-weight: 700;
    text-align: center;
    }
    .signin__button button{
        display: none;
    }
    .signin__button__mobile{
    flex: 40%;
    display: block;
    padding: 10px 15px !important;
    margin-right: 20px;
    font-size: 10px;
    text-align: center;
    background-color: transparent;  
    color: #0087ee;

}
.after__signin{
      color: #0087ee;
      display: flex;
      align-items: center;
      padding: 10px 15px !important;
      margin-right: 30px;
}

     .navbar .getQuote__btn  {
        display: none;
    }
}





